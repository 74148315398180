a<template>
  <div class="livenessAuth">
    <mt-header :title="$t('livenessAuth')">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)">
          <img src="@/assets/back1.png" height="20" width="20" slot="icon">
          {{ $t('back') }}
        </mt-button>
      </router-link>
    </mt-header>
    <step-item :step="6" />
    <div class="content">
      <upload-image ref="uploadImage" v-model="images" behavior="P05" />
    </div>
    <div class="bottom">
      <mt-button type="primary" size="large" @click="submit">{{
        $t("nextStep")
      }}</mt-button>
    </div>
    <popup-picker ref="popupPicker" />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { emptyRules, idcardRules } from "../../utils/rules";
import { applyStep } from '../../utils/api'
import popupPicker from '@/components/popupPicker.vue'
import stepItem from '../../components/stepItem.vue'
import uploadImage from './UploadImage2.vue'
import branch from '../../../node_modules/branch-sdk'
import Vue from 'vue'
import gtmPageView from '../../utils/gtm.js'
import mixin from './mixin'
export default {
  name: 'LivenessAuth',
  mixins: [mixin],
  components: {
    uploadImage,
    popupPicker,
    stepItem,
  },
  data() {
    return {
      isCheck: false,
      form: {
        docType: '',
        idcard: '',
      },
      images: [],
    }
  },
  computed: {
    ...mapState(["apply", "user"]),
    docTypeState() {
      this.onStorageItem('docType')
      return this.isCheck ? emptyRules(this.form.docType) : null;
    },
    idcardState() {
      this.onStorageItem('idcard')
      return this.isCheck ? idcardRules(this.form.idcard, this.form.docType) : null;
    },
  },
  created() {
    gtmPageView(this)
    Object.keys(this.form).forEach((val) => {
      this.form[val] = this.apply[val] || ''
    })
    this.init()
  },
  mounted() {
    this.BEHAVIOR_ADD({
      id: 'P06_C00'
    })
  },
  destroyed() {
    this.BEHAVIOR_ADD({
      id: 'P06_C99'
    })
    this.BEHAVIOR_SEND()
  },
  methods: {
    ...mapMutations(["SET_USER"]),
    ...mapMutations('behavior', ["BEHAVIOR_ADD"]),
    ...mapActions('behavior', ["BEHAVIOR_SEND"]),
    init() {
      this.GET_DOC_TYPE() // 获取单据类型
    },
    // 提交下一步
    async submit() {
      this.isCheck = true;
      var uploadImageError = this.$refs.uploadImage.validation()
      if (uploadImageError) return this.$toast(uploadImageError)
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start()
      try {
        // 自定义事件
        let pixel = window.Vue.$route.query['pixel'] || localStorage['pixel']
        if (pixel) {
          Vue.analytics.fbq.event('05_IDCARD_AUTH', { content_name: '05_IDCARD_AUTH' })
        }
        // Google Analytics  IDCARD_AUTH
        if (localStorage['ga']) {
          window.gtag('event', 'IDCARD_AUTH')
        }
      } catch(e) {
        console.log(e)
      }
      var idcard = this.form.idcard.replace(/[^a-zA-Z0-9]/g, '');
      if (this.form.docType == 'PY06') {
          idcard = idcard.replace(/[^0-9]/g, '');
      }
      let data = {
        currentStep: 6,
        totalSteps: 8,

        applyId: this.user.applyId,

        ...this.form,
        idcard: idcard,
        images: this.images.map(num => ({
          imageId: num
        }))
      }
      await this.$axios({
        method: "post",
        url: applyStep,
        data: data,
      })
        .then(async (e) => {
          if (e.status.code == "000") {
            this.SET_USER({
              ...this.user,
              applyId: e.body.applyId
            })
            if (e.body.name) {
              this.SET_USER({
                ...this.user,
                name: e.body.name
              })
            }
            this.$router.push('/step/paymentMethod')
          } else {
            this.$toast(e.status.msg);
          }
        })
        .catch(() => {});
      this.$indicator.close();
      this.$NProgress.done()
      branch.logEvent('05_IDCARD_AUTH', { metadata: data })
    }
  }
}
</script>

<style lang="scss" scoped>
.livenessAuth {
  background: linear-gradient(to bottom, #BB97FA, #855EF4);
  min-height: 800px;
  .content {
    margin: 10px;
    > .title {
      font-size: 14px;
      color: #4F9B41;
      padding: 0px 20px;
    }
    .item {
      margin-top: 20px;
      .mint-cell {
        background: white;
        /deep/ .mint-cell-wrapper {
          background: none;
        }
      }
      .title {
        margin: 6px 15px;
        font-size: 16px;
        color: #1D1D1D;
        &::after {
          content: '*';
          font-size: 14px;
          vertical-align: top;
          color: #E92121;
        }
      }
      .mint-field {
        border-radius: 6px;
        min-height: 46px;
      }
    }
  }
  .iconfont {
    margin: 0 5px;
    color: #4F9B41;
  }
  .bottom {
    margin-top: 60px;
    padding: 20px 20px 40px 20px;
    .mint-button {
      border-radius: 6px;
      background: linear-gradient(to bottom, #F9F6FF, #E2D6FF);;
      color: #855EF4;
    }
  }
}
</style>